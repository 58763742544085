import Button from "./Button";
import Input from "./Input";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { emailInValidMessage, passwordInValidMessage, emailInputAllowedSigns, validateEmail, validatePassword, allSigns255 , validateClientLoginName, clientLoginNameInValidMessage} from '../services/validate';

const LoginForm = ({ sumbitHandler, isInputName }) => {

    const inputRef = useRef(null);
    const passwordInputRef = useRef(null);


    const onSubmitHandler = (e) => {
        e.preventDefault()

        if (!inputRef.current?.isValid()) return;

        else if (!passwordInputRef.current?.isValid()) return;

        let credentials;

        if (isInputName) {

            credentials = {
                "login": inputRef.current?.getValue(),
                "password": passwordInputRef.current?.getValue()
            }

        } else {
            credentials = {
                "email": inputRef.current?.getValue(),
                "password": passwordInputRef.current?.getValue()
            }
        }

        sumbitHandler({ credentials })
    }


    return (
        <form className="flex flex-col gap-3 items-center justify-center w-full" onSubmit={onSubmitHandler} noValidate>

            <Input
                name={isInputName ? "Nazwa firmy" : "Adres email"}
                type={isInputName ? "text" : "email"}
                svg={isInputName ? 
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" /> 
                :
                <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />}                
                isRequired={true}
                inValidMessage={isInputName? clientLoginNameInValidMessage: emailInValidMessage}
                inputValidatingFunc={isInputName? validateClientLoginName: validateEmail}
                allowedSigns={isInputName ? allSigns255 : emailInputAllowedSigns}
                ref={inputRef}
            />

            <Input
                name="Hasło"
                type={"password"}
                svg={<path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />}
                isRequired={true}
                inValidMessage={passwordInValidMessage}
                inputValidatingFunc={validatePassword}
                allowedSigns={allSigns255}
                ref={passwordInputRef}
            />

            <Button value={'Zaloguj się'} isActive={true} />

           {!isInputName && <Link to="/admin/password/forget" className="text-left text-gray-500 w-full hover:underline ml-1"> Nie pamiętasz hasła?</Link>} 

        </form>
    );
}

export default LoginForm

